<template>
  <div>
    <div v-if="menu_role_group_id=='5be25ecb-1a4a-43fb-9bd2-bca6b23d4d10'">
      <CustomerDashboard/>
    </div>
  </div>
</template>

<script>
import { BCard, BButton, BCardText, BRow, BCol, BAvatar, BImg } from 'bootstrap-vue'
import CustomerDashboard from '@/components/CustomerDashboard/CustomerDashboard'
import Ripple from 'vue-ripple-directive'
// import { $themeColors } from '@themeConfig'
export default {
  name: 'index',
  data() {
    return {
      menu_role_group_id: null
    }
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    BImg,
    CustomerDashboard
  },
  directives: {
    Ripple,
  },
  methods: {},
  created() {
    //init menu role group id
    this.menu_role_group_id = this.$store.getters["auth/userInfo"].menu_role_group_id;

    // console.log(this.$store.getters["auth/token"]);
    // console.log(this.$store.getters["auth/userInfo"]);
    // console.log(this.$store.getters["auth/optionList"]);
  }
}
</script>

<style scoped>

</style>
