<template>
  <div>
    <div class="card p-2">
      <div class="row">
        <h4 class="media-heading p-1">
          {{ $t('Welcome') }}
          {{ `${$store.getters['auth/userInfo'].name} ${$store.getters['auth/userInfo'].last_name}` }}!
        </h4>
      </div>
      <div class="row">
        <div class="col">
          <b-card no-body class="card-statistics border border-3" >
            <b-card-header class="p-1">
              <div>
                  <b-button size="btn" class="m-0 mr-1 mb-1 p-1    border-3"  @click="() => this.$router.push({name:'purchased'})" variant="primary">
                     {{ $t('Buy History') }}
                  </b-button>
                  <b-button size="btn" class="m-0 mb-1 p-1   border-3"  @click="() => this.$router.push({name:'shop',params: {personID: person_id }})" variant="success">
                     {{ $t('Shop') }}
                  </b-button>
            </div>

            </b-card-header>
            <b-card-body class="statistics-body">
              <b-row>
             
                <b-col  md="6" sm="12"  class="mb-2 mb-md-0">
                  <b-media no-body>
                     <b-media-aside  class="mr-2">
                      <b-avatar size="45"  variant="light-success" class="border border-3">
                        <feather-icon size="30" icon="BoxIcon"/>
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ ActiveProductCount }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t('Active Packages') }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col md="4" sm="6" class="mb-2 mb-md-0">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="45" variant="light-danger" class="border border-3">
                        <feather-icon size="30" icon="BoxIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ ConsumedProductCount }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{$t('Consumed Packages')}}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </div>
        <div class="col">
          <b-card no-body class="card-statistics border">
          <b-card-header class="p-1">
            <div>
                <b-button size="btn" class="m-0 mr-1 mb-1 p-1 btn btn-default rounded border  border-3"  @click="() => this.$router.push({name:'myAppointments'})" variant="primary">
                     {{ $t('My Appointments') }}
                </b-button>
                <b-button size="btn" class="m-0 p-1 mb-1 btn btn-default rounded border  border-3"  @click="() => this.$router.push({name:'selectProduct',params: {personID: person_id }})" variant="success">
                     {{ $t('Make Appointment') }}
                </b-button>
            </div>
          </b-card-header>
          <b-card-body class="statistics-body">
              <b-row>
                <b-col md="4" sm="6" class="mb-2 mb-md-0" >
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="45" variant="light-success" class="border border-3">
                        <feather-icon size="30" icon="WatchIcon"/>
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ ComingAppointmentCount }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{$t('Upcoming Appointments')}}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col md="4" sm="6" class="mb-2 mb-md-0">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="45" variant="light-warning" class="border border-3">
                        <feather-icon size="30" icon="WatchIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ ConsumedAppointmentCount }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{$t('Expired')}}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col md="4" sm="6" class="mb-2 mb-md-0" >
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="45" variant="light-danger" class="border border-3" >
                        <feather-icon size="30" icon="WatchIcon"/>
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ CanceledAppointmentCount }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{$t('Canceled')}}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </div>
      </div>
      <!-- <div class="row">
        <div class="pl-1">
          <b-card-title class="shadow p-1 rounded border border-3">{{$t('Defined By Your Facility')}}</b-card-title>
        </div>
      </div> -->
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <b-card
              no-body class="border border-3 cursor-pointer box-shadow-hover"
              @click="() => this.$router.push({name:'shop', params: { personID: $store.getters['auth/userInfo'].person_id , role: 'customer'}})">
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ ProductCount }} {{ $t('Pieces') }}
                </h2>
                <span>{{ $t('Purchasable packages') }}</span>
              </div>
              <b-avatar variant="primary" size="45" class="border border-3">
                <feather-icon size="21" icon="BoxIcon"/>
              </b-avatar>
            </b-card-body>
          </b-card>
        </div>
        <div class="col-sm-12 col-md-4">
          <b-card no-body class="border border-3 box-shadow-hover cursor-pointer"
                  @click="() => this.$router.push({name:'activeTrainers'})">
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ActiveTrainerCount}} {{$t('Trainer')}}
                </h2>
                <span>{{$t('Appointable trainers')}}</span>
              </div>
              <b-avatar variant="info" size="45" class="border border-3">
                <feather-icon size="21" icon="UserIcon"/>
              </b-avatar>
            </b-card-body>
          </b-card>
        </div>
        <div class="col-sm-12 col-md-4">
          <b-card no-body class="border border-3 box-shadow-hover cursor-pointer"
                  @click="() => this.$router.push({name:'selectProduct'})"
          >
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ ActivityCount }} {{$t('Activity')}}
                </h2>
                <span>{{$t('Appointable activities')}}</span>
              </div>
              <b-avatar
                  variant="success"
                  size="45"
                  class="border border-3"
              >
                <feather-icon
                    size="21"
                    icon="ActivityIcon"
                />
              </b-avatar>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
  BButton
} from 'bootstrap-vue'

export default {
  name: 'CustomerDashboard',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BButton
  },
  computed: {
    moment() {
      return new Date()
    }
  },
  data() {
    return {
      comingAppointmentCount: 0,
      expiredAppointmentCount: 0,
      canceledAppointmentCount: 0,
      productCount: 0,
      activeProductCount: 0,
      passiveProductCount: 0,
      facilityProductCount: 0,
      facilityActivityCount: 0,
      facilityTrainerCount: 0,

      myAppointments: [],
      myBaseAppointments: [],
      productBaseData: [],
      timeTableDetails: [],
      optionsTrainer: [],
      ConsumedProductCount: 0, 
      ActiveProductCount: 0,
      TotalProductCount: 0,
      ActiveTrainerCount:0,
      ActivityCount:0,
      ProductCount:0,
      ConsumedAppointmentCount:0,
      ComingAppointmentCount:0,
      CanceledAppointmentCount:0,


      person_id:null,
      company_branch_id:null
    }
  },
  methods: {
    // time table details tablosundan bana ait randevuları getir
    getMyAppointments() {
      this.$database.AppointmentService.get_my_appointments(
          this.$store.getters['auth/userInfo'].company_branch_id,
          this.$store.getters['auth/userInfo'].person_id
      )
          .then((res) => {
            if (res.is_success === true || res.is_success === 1) {
              this.myBaseAppointments = res.result
              this.myAppointments = this.myBaseAppointments

              //coming appointment
              let dataComingAppointment = []
              this.myBaseAppointments.map(el => {
                if (this.moment < new Date(el.end_date) && el.is_active == true) {
                  dataComingAppointment.push(el)
                }
              })
              this.comingAppointmentCount = dataComingAppointment.length

              //expired appointment
              let dataExpiredAppointment = []
              this.myBaseAppointments.map(el => {
                if (this.moment > new Date(el.end_date) && el.is_active == true) {
                  dataExpiredAppointment.push(el)
                }
              })
              this.expiredAppointmentCount = dataExpiredAppointment.length

              //cancelled appointment
              let dataCancelledAppointment = []
              this.myBaseAppointments.map(el => {
                if (el.is_active == false) {
                  dataCancelledAppointment.push(el)
                }
              })
              this.canceledAppointmentCount = dataCancelledAppointment.length
            }
          })
    },
    //Müşterinin ürünleri
    getCustomerProductsForSelect() {
      this.$database.AppointmentService.get_customer_all_products(
          this.$store.getters['auth/userInfo'].person_id,
          this.$store.getters['auth/userInfo'].company_branch_id
      )
          .then((res) => {
            if (res.is_success === true || res.is_success === 1) {
              res.result.map((el) => {
                this.productBaseData.push({
                  ...el,
                  full_name: el.name + ' ' + el.last_name,
                })
              })
            }

            this.productCount = this.productBaseData.length
            //aktif ürünleri filtrelemek
            this.getActiveProduct()
            //pasif ürünleri filtrelemek
            this.getPassiveProduct()
          })
    },
    //Müşteriye ait time table verileri
    getTimeTableDetailsDataByPerson() {
      this.$database.AppointmentService.get_timetable_activity(
          this.$store.getters['auth/userInfo'].person_id
      )
          .then(res => {
            if (res.is_success === true || res.is_success === 1) {
              res.result.map(el => {
                this.timeTableDetails.push(el)
              })
            }
          })
    },
    getCustomerDashBoardView(){
        this.$database.CustomerService.CustomerDashBoardView(
          this.$store.getters["auth/userInfo"].company_branch_id,
          this.$store.getters["auth/userInfo"].person_id
        ).then(res => {
          if(res.IsSuccess == 1 || res.IsSuccess == true){
            this.ConsumedProductCount = res.Result.ProductInfo.ConsumedProductCount
            this.ActiveProductCount = res.Result.ProductInfo.ActiveProductCount
            this.TotalProductCount = res.Result.ProductInfo.AllProductCount
            this.ActiveTrainerCount= res.Result.StudioInfo.ActiveTrainerCount
            this.ActivityCount=res.Result.StudioInfo.ActivityCount
            this.ProductCount=res.Result.StudioInfo.ProductSoldCount
            this.ConsumedAppointmentCount=res.Result.AppointmentInfo.ConsumedAppointmentCount
            this.ComingAppointmentCount= res.Result.AppointmentInfo.ComingAppointmentCount
            this.CanceledAppointmentCount=res.Result.AppointmentInfo.CanceledAppointmentCount
          }else {
            console.error(res.data.Result)
          }
        })
    },
    getCount(trainer_product_id, customer_product_sales_id) {
      let count = 0
      let dataArray = []
      //time table dan gelen müşteri kayıtları ile alınan ürün kayıtlarının product id lerini karşılaştırırım
      this.timeTableDetails.map(el => {
        if (el.trainer_product_id == trainer_product_id
            &&
            el.customer_product_sales_id == customer_product_sales_id) {
          dataArray.push(el)
          count++
        }
      })
      return {
        count,
        dataArray
      }
    },
    getActiveProduct() {
      this.productBaseData.map(el => {
        var count = this.getCount(el.trainer_product_id, el.customer_product_sales_id).count
        if (count < el.quantity * el.session) {
          this.activeProductCount++
        }
      })
    },
    getPassiveProduct() {
      this.productBaseData.map(el => {
        var count = this.getCount(el.trainer_product_id, el.customer_product_sales_id).count
        if (count >= el.quantity * el.session) {
          this.passiveProductCount++
        }
      })
    },
    //Tesise ait ürünleri getir
    // getProductData() {
    //   this.$database.ShoppingService.get_product_list(
    //       this.$store.getters['auth/userInfo'].company_branch_id
    //   )
    //       .then((res) => {
    //         if (!res.is_success) return
    //         if (!res.result) {
    //           return
    //         } else {
    //           this.facilityProductCount = res.result.length
    //         }
    //       })
    // },
    //Tesise ait aktiviteleri getir
    // getActivitiesData() {
    //   this.$database.ShoppingService.get_activity_list(
    //       this.$store.getters['auth/userInfo'].company_branch_id
    //   )
    //       .then((res) => {
    //         if (!res.is_success) return
    //         if (!res.result) {
    //           return
    //         } else {
    //           this.facilityActivityCount = res.result.length
    //         }
    //       })
    // },
      //Tesise ait eğitmenleri getir
      // getTrainerData() {
      //   this.$database.ShoppingService.get_trainer_list(
      //       this.$store.getters["auth/userInfo"].company_branch_id
      //   )
      //       .then((res) => {
      //         if (!res.is_success) return
      //         if (!res.result) {
      //           return
      //         } else {
      //           this.optionsTrainer = res.result.map((el) => {
      //             const full_name = el.name + ' ' + el.last_name
      //             return {
      //               ...el,
      //               full_name,
      //             }
      //           })
      //         }
      //         this.facilityTrainerCount = this.optionsTrainer.length;
      //       })
      // },
  },
  created() {

   this.person_id= this.$store.getters['auth/userInfo'].company_branch_id,
   this.company_branch_id= this.$store.getters['auth/userInfo'].person_id

    // this.getTimeTableDetailsDataByPerson();
    // this.getCustomerProductsForSelect();
   //  this.getMyAppointments();
   
   
   //İptal edilen metodlar
    // this.getProductData();
    // this.getActivitiesData();
   //  this.getTrainerData();
    this.getCustomerDashBoardView();
  },

}
</script>

<style scoped>
.box-shadow-hover:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;
}
</style>
